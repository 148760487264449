import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'mobx-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
import api from './api';
import { clinicStore } from './stores/ClinicStore';
import { serviceStore } from './stores/ServiceStore';
import { userStore } from './stores/UserStore';
import ClinicPage from './pages/ClinicPage';
import ServicesPage from './pages/ServicesPage';
import UsersPage from './pages/UsersPage';
import DataMigrationPage from './pages/DataMigrationPage';
import LaunchDatePage from './pages/LaunchDatePage';
import Header from './components/common/Header';
import SideNavBar from './components/common/SideNavBar';
import Footer from './components/common/Footer';
import queryString from 'query-string';
import { ACCESS_KEY, BUSINESS_ID, CLINIC_ID } from './constants/storageKeys';

import './App.css';
import UploadPage from './pages/UploadPage';

function App() {
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const accessKey = query.key;
    if (accessKey === 'clear') {
      window.localStorage.clear();
      setLoadingMessage('Local settings cleared!');
      return;
    }

    if (window.localStorage.getItem(BUSINESS_ID)) { // already initialized; no need to call backend
      setReady(true);
      return;
    }

    if (accessKey) {
      let key = window.localStorage.getItem(ACCESS_KEY);
      console.log('ACCESS KEY!!!!!', key);
      if (!key) {
        window.localStorage.setItem(ACCESS_KEY, accessKey);
        api.Business.get().then(({ data }) => {
          if (data.id) {
            setReady(true);
            window.localStorage.setItem(BUSINESS_ID, data.id);
            window.localStorage.setItem(CLINIC_ID, data.clinics[0].id);
          }
          else {
            alert('ERROR: Cannot locate valid business');
          }
        });
      }
    }
  }, []);

  if (!ready) {
    return <div>{loadingMessage}</div>; // Optionally provide a loading screen or spinner
  }

  return (
    <Provider clinicStore={clinicStore} serviceStore={serviceStore} userStore={userStore}>
      <Router>
        <div className="d-flex flex-column vh-100  w-100">
          <Header />
          <div className="d-flex flex-grow-1 overflow-hidden">
            <SideNavBar />
            <div className="bg-light content-body overflow-auto w-100">
              <Routes>
                <Route path="/" element={<Navigate replace to="/clinic" />} />
                <Route path="/clinic" element={<ClinicPage id={window.localStorage.getItem(CLINIC_ID)} />} />
                <Route path="/data-migration" element={<DataMigrationPage clinicId={window.localStorage.getItem(CLINIC_ID)} />} />
                <Route path="/upload" element={<UploadPage clinicId={window.localStorage.getItem(CLINIC_ID)} />} />
                <Route path="/services" element={<ServicesPage clinicId={window.localStorage.getItem(CLINIC_ID)} />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/launch-date" element={<LaunchDatePage clinicId={window.localStorage.getItem(CLINIC_ID)} />} />
                {/* More routes can be added here */}
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;