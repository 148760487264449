import React, { useRef, useEffect, useContext, useState } from 'react';
import { Form, GroupItem, SimpleItem, Item, Label, RequiredRule, EmailRule, PatternRule, ButtonItem } from 'devextreme-react/form';
import { observer, inject } from 'mobx-react';
import NavButtons from '../components/common/NavButtons';
import { LoadIndicator } from 'devextreme-react';

const ClinicPage = inject('clinicStore')(observer(({ clinicStore, id }) => {
  const isMounted = useRef(true);
  const [ready, setReady] = useState(false);
  const [formData, setFormData] = useState(null);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    // Assuming `formRef.current` provides access to the form API
    const updatedFormData = { ...formData };

    // Retrieve all data from the form
    const formInstance = formRef.current.instance;

    //formInstance.validate(); // Validates the form and updates the formData state

    // Update formData based on the latest values from form fields
    // This "refreshing" the formData is a workaround; when Chrome autofills certain fields, the data appear on the screen (UI populated) but it never triggers any events
    // so DX sometiems missed updating the models. That's why I'm refreshing here just in case.
    // And the phoneMask somehow prevented option('value') returning the currect value. But I found the value in "_value" property. Strange. 
    Object.keys(formData).forEach(key => {
      if (formInstance.getEditor(key)) {
        formInstance.getEditor(key).blur();
        updatedFormData[key] = formInstance.getEditor(key).option('value') ? formInstance.getEditor(key).option('value') : formInstance.getEditor(key)._value;
      }
    });

    setFormData(updatedFormData);
    await clinicStore.update(updatedFormData);
  };


  useEffect(() => {
    clinicStore.refresh(id).then(data => {
      setFormData({ ...clinicStore.data });
      setReady(true);
    });

    return () => {
      isMounted.current = false;
    }
  }, []);  // eslint-disable-line

  const phoneEditorOptions = {
    mask: '+1 (000) 000-0000',
    // maskRules: {
    //   X: /[02-9]/,
    // },
    // maskInvalidMessage: 'The phone must have a correct North American phone format',
  };
  const phonePattern = /^[02-9]\d{9}$/;

  return (
    <>
      <div className='p-4 w-50' >
        <h3>Clinic Information</h3><p></p>
        {
          ready ? <>
            <Form ref={formRef} formData={formData} showColonAfterLabel={false} onFieldDataChanged={handleSubmit}
            >
              <GroupItem caption="Business Name">
                <SimpleItem dataField="legalName" label={{ visible: false }}>
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Business Address">
                <SimpleItem dataField="country" editorOptions={{ disabled: true }} />
                <Item dataField="line1">
                  <Label text='Line 1'></Label>
                </Item>
                <Item dataField="line2">
                  <Label text='Line 2'></Label>
                </Item>
                <GroupItem colCount={2}>
                  <Item dataField="unitSuiteType">
                    <Label text='Unit Type'></Label>
                  </Item>
                  <Item dataField="unitSuiteNumber">
                    <Label text='Unit #'></Label>
                  </Item>
                </GroupItem>
                <GroupItem colCount={2}>
                  <SimpleItem dataField="city" />
                  <SimpleItem dataField="region" />
                </GroupItem>
                <SimpleItem dataField="postalCode" editorOptions={{}} />
              </GroupItem>
              <GroupItem caption="Primary Email Addres">
                <SimpleItem dataField="emailAddress" label={{ visible: false }}>
                  <EmailRule message="Email is invalid" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Primary Phone No.">
                <SimpleItem dataField="phoneNumber" label={{ visible: false }}
                  editorOptions={phoneEditorOptions}
                >
                  {/* <PatternRule
                message="The phone must have a correct North American phone format"
                pattern={phonePattern}
              /> */}
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Primary Fax No.">
                <SimpleItem dataField="faxNumber" label={{ visible: false }}
                  editorOptions={phoneEditorOptions}
                >
                  {/* <PatternRule
                message="The phone must have a correct North American phone format"
                pattern={phonePattern}
              /> */}
                </SimpleItem>
              </GroupItem>
            </Form>
            <NavButtons currentRoute='/clinic' />
          </> : <LoadIndicator height={40} width={40} />
        }
      </div>
    </>
  );
}));

export default ClinicPage;