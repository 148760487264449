import React, { useContext, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { OnboardingStatusType } from '../../constants/common';


const SideNavBar = inject('clinicStore')(observer(({ clinicStore }) => {
  useEffect(() => {
    if (clinicStore.status == null) {
      clinicStore.refresh();  // Assuming this is an async method
      setTimeout(() => {
        console.log('clinicStore.status & OnboardingStatusType.DatabaseParsed!!!!', clinicStore.status,  OnboardingStatusType.DatabaseParsed, clinicStore.status & OnboardingStatusType.DatabaseParsed)
      }, 2000)
    }
  }, [clinicStore]);

  return (
    <nav className="text-white flex-shrink-0 p-3" style={{ width: '250px', backgroundColor: '#004d4d' }}>
      <ul className="nav flex-column">
        <li className="nav-item"><Link to="/clinic" className="nav-link text-white">Clinic</Link></li>
        {
        /* 
          <li className="nav-item"><Link to="/data-migration" className="nav-link text-white">Data Migration</Link></li>
          <li className="nav-item"><Link to="/upload" className="nav-link text-white">Files Backup</Link></li> 
          */
        }
        {
          (clinicStore.status & OnboardingStatusType.DatabaseParsed) > 0 &&
          <>
            <li className="nav-item"><Link to="/services" className="nav-link text-white">Services</Link></li>
            <li className="nav-item"><Link to="/users" className="nav-link text-white">Users</Link></li>
            <li className="nav-item"><Link to="/launch-date" className="nav-link text-white">Launch Date</Link></li>
          </>
        }
      </ul>
    </nav>
  );
}));

export default SideNavBar;